import React from 'react';
import { Title } from '@vds/typography'
import { Context } from '../Store.js'
import { useHistory } from 'react-router-dom'
import { Line } from '@vds/lines';
import { Link } from 'react-router-dom'

function FollowCoin () {

  const { state } = React.useContext(Context)
  const { user, coin } = state
  const history = useHistory()

  if (!user || !coin) {
    history.replace('/coin')
    return null
  }

  return (
    <div className="followCoin pt100 m0auto fixed-width-lg h-100">
      <div className="mx-auto w-100 h-100 d-flex flex-column justify-content-between">
        <div className="top">
          <div className="w-100 p-3 leading-loose">
            <Title size="large" primitive="h1">{`Welcome Back${user ? `, ${user.first_name}` : ''}.`}</Title>
            <Line type="heavy"/>
          </div>

          <div className="fixed-width mb-4 p-3">
            <h1 className="followCoinTitle bold">Check out where your Purpose Coin has been.</h1>
            <div className="w-100 mt-3 d-flex justify-content-left">
              <Link to={`/feed/${coin.number}`}>Follow the Coin</Link>
            </div>
          </div>
        </div>
        <div className="bottom bg-black">
          <div className="fixed-width mt-4 mb-4 p-3">
            <h1 className="followCoinTitle whiteFont mb-4">See who the most recent Purpose Champions are and why they've been chosen.</h1>
            <div className="w-100 mt-3 d-flex justify-content-left">
              <Link to={`/activity_feed`}>The Feed</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FollowCoin;
