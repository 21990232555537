import React from 'react';
import { Title, Body } from '@vds/typography'
import axios from 'axios'
import { apiURL } from '../constants'
import { Context } from '../Store.js'
import { useHistory } from 'react-router-dom'
import { Line } from '@vds/lines';
import { TextLink } from '@vds/buttons'

function TransferCard ({user, created_at, transfers, index}) {
  return (
    <div className='transfer-card'>
      <div className='left-side'>
        <div className='transfer-count-container'>
          <div className='transfer-count'>
            { transfers.length - index }
          </div>
        </div>
        <div className="user-info">
          <div className="bold">{`${user.first_name}`}</div>
          <Body>{`${created_at}`}</Body>
          <Body>{user.formatted_address ? `${user.formatted_address}` : ''}</Body>
        </div>
      </div>
    </div>
  )
}

function FollowCoinFeed () {

  const {dispatch, state} = React.useContext(Context)
  const { user, transfers, coin } = state
  const history = useHistory()

  React.useEffect(() => {
    fetchCoinTransfers()
  }, [])

  React.useEffect(() => {
  }, [transfers])

  if (!user || !coin) {
    history.replace('/coin')
    return null
  }

  async function fetchCoinTransfers () {
    try {
      const res = await axios.get(`${apiURL}/coins/${coin.id}/transfers.json`)
      dispatch({
        type: "SET_TRANSFERS",
        transfers: res.data.transfers
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="container mx-auto w-100">
      <div className="p-6">
        <div className="w-100 mb-4 leading-loose">
          <Title size="large" primitive="h1">Follow your coin.</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-4">
          <Body size="large" viewport="desktop" primitive="h2">Check out where your Purpose Coin has been. And watch as we move #ForwardTogether.</Body>
        </div>

        {
          transfers &&
            transfers.reverse().map(({created_at, user}, index) => {
              return <TransferCard
                index={index}
                user={user}
                created_at={created_at}
                transfers={transfers}
                key={index}
              />
            })
        }
      </div>

      <div className="p-6">
      </div>

    </div>
  )
}

export default FollowCoinFeed;
