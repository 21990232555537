import React from 'react';
import { Title } from '@vds/typography'
import { Body } from '@vds/typography';
import { InputField } from '@vds/inputs';
import { Button } from '@vds/buttons';
import { Formik } from 'formik'
import { Context } from '../Store.js'
import { Line } from '@vds/lines';
import { updateRecipientFormFieldValues } from '../formHelpers'
import ConnectedNotification from './ConnectedNotification'
// import * as yup from 'yup'

// let schema = yup.object().shape({
//   email: yup.string().email().required(),
//   emailConfirmation: yup.string().required('confirmation is required'),
//   first_name: yup.string().required('first name required'),
//   last_name: yup.string().required('last name required')
// })

export default function RecipientStepOne () {

  console.log('update recepient form', updateRecipientFormFieldValues)

  const { state, dispatch } = React.useContext(Context)
  const { recipientFormWizard } = state

  return (
    <div className="container">
      <ConnectedNotification />
      <div className="px-6 py-8">
        <div className="w-100 mb-4 flex justify-center">
          <Title size="large" primitive="h1">This is where it begins.</Title>
          <Line type="heavy"/>
        </div>

        <div className="w-100 mb-3">
          <Body size="large" viewport="desktop" primitive="h4">Thank you for helping us to recognize and reward V Teamers who truly embody what it means to move #ForwardTogether.</Body>
        </div>

        <div className="w-100 mb-3">
          <Body size="large" viewport="desktop" primitive="h4">Let us know who you'll be passing your Purpose Coin to.</Body>
        </div>

        <div className="w-100 mb-3">
          <Body size="large" viewport="desktop" primitive="h4">Enter their information below.</Body>
        </div>

        <Formik
          initialValues={{  first_name: '', last_name: '',  email: '', emailConfirmation: '', }}
          isValidating={false}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = 'Email Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) || !/^\w+([@]\w+)*@*.*verizon.*$/g.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (values.email !== values.emailConfirmation) {
              errors.emailConfirmation = 'Confirmation email doesn not match';
            }
            if (!values.first_name) {
              errors.first_name = 'First name is required';
            }
            if (!values.last_name) {
              errors.last_name = 'Last name is required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateRecipientFormFieldValues({
              index: 1,
              dispatch,
              recipientFormWizard,
              values,
              setSubmitting
            })
            dispatch({type: "CLEAR_ERROR"})
          }}
        >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form className="w-100 form-group" onSubmit={handleSubmit}>
          <div className="w-100 mb-3">
            <div className="w-100">
              <InputField
                label="Recipient's First Name"
                placeholder="Recipient's First Name"
                width="inherit"
                type="first_name"
                name="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                error={!!errors.first_name && !!touched.first_name}
                errorText={errors.first_name}
              />
            </div>
          </div>
          <div className="w-100 mb-3">
            <div className="w-100">
              <InputField
                label="Recipient's Last Name"
                placeholder="Recipient's Last Name"
                width="inherit"
                type="last_name"
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
                error={!!errors.last_name && !!touched.last_name}
                errorText={errors.last_name}
              />
            </div>
          </div>
          <div className="w-100 mb-3">
            <div className="w-100">
              <InputField
                label="Recipient's Email"
                placeholder="Recipient's Email"
                width="inherit"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!errors.email && !!touched.email}
                errorText={errors.email}
              />
            </div>
          </div>
          <div className="w-100 mb-4">
            <div className="w-100">
              <InputField
                label="Verify Email Address"
                placeholder="Verify Email Address"
                width="inherit"
                type="emailConfirmation"
                name="emailConfirmation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailConfirmation}
                error={!!errors.emailConfirmation && !!touched.emailConfirmation}
                errorText={errors.emailConfirmation}
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-left">
            <Button className="w-100-mobile" type='submit' disabled={isSubmitting}>
              Next
            </Button>
          </div>
        </form>
      )}
    </Formik>
    </div>
  </div>
  )
}
