import React, { useEffect } from 'react';
import { Title } from '@vds/typography'
import { Context } from '../Store.js'
import { useHistory } from 'react-router-dom'
import { Line } from '@vds/lines';

function TransferCard ({ transfer }) {
  return (
    <div className='activity-card'>
      <div className="user-info">
        <div className="greyFont">{new Intl.DateTimeFormat('en-US', {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
            }).format(new Date(transfer.created_at))}
        </div>
        <h3 className="bold">{transfer.first_name} recieved a Purpose Coin from {transfer.giver_name}!</h3>
      </div>
    </div>
  )
}

function ActivityFeed () {

  const { state } = React.useContext(Context)
  const { user, coin, allTransfers } = state
  const history = useHistory()

  useEffect(() => {
  }, [allTransfers])

  if (!user || !coin) {
    history.replace('/coin')
    return null
  }

  return (
    <div className="container mx-auto w-100">
      <div className="p-6">
        <div className="w-100 mb-4 leading-loose">
          <Title size="large" primitive="h1">The feed.</Title>
          <Line type="heavy"/>
        </div>

        {
          allTransfers &&
            allTransfers.map((transfer, i) => {
              return <TransferCard
                transfer={transfer}
                key={i}
              />
            })
        }
      </div>

      <div className="p-6">
      </div>

    </div>
  )
}

export default ActivityFeed;
