import React, { useReducer, useEffect } from 'react'
export const Context = React.createContext()

const initialState = {
  screen: 'CoinInput',
  coin: null,
  coins: null,
  user: null,
  coin: null,
  fetching: false,
  error: null,
  childErrorMessage: null,
  recipientFormWizard: {
    index: 0,
    formFields: {
      first_name: '',
      last_name: '',
      email: '',
      notes: ''
    }
  },
  verifyCoinWizard: {
    index: 0,
    formFields: {
      email: null,
      first_name: '',
      last_name: ''
    }
  },
  transfers: null,
  transfer: null,
  allTransfers: null,
  uploaded_filepath: null
}

// const initialState = {
//   screen: 'CoinInput',
//   coin: null,
//   coins: null,
//   user: {
//     coin: {
//       created_at: "2019-11-13T15:41:54.352Z",
//       id: 17,
//       number: 101,
//       original_owner_id: null,
//       updated_at: "2019-11-13T15:41:54.352Z",
//     },
//     created_at: "2019-11-13T15:42:11.400Z",
//     email: "tanner@gmail.com",
//     first_name: "thomas",
//     id: 47,
//     is_owner: true,
//     last_name: "Yancey",
//     location: null,
//     updated_at: "2019-11-13T20:48:12.311Z",
//     url: "https://admin.vzpurposecoin.com/users/47.json",
//     verified: true,
//   },
//   fetching: false,
//   error: null,
//   childErrorMessage: null,
//   recipientFormWizard: {
//     index: 0,
//     formFields: {
//       first_name: '',
//       last_name: '',
//       email: '',
//       notes: ''
//     }
//   },
//   verifyCoinWizard: {
//     index: 0,
//     formFields: {
//       email: null,
//       first_name: '',
//       last_name: ''
//     }
//   },
//   transfers: null,
//   transfer: {
//     id: 69
//   },
//   uploaded_filepath: null
// }



export const reducer = (state, action) => {
  console.log('%c Action %o', 'color: green; font-weight: bold;', action)
  switch (action.type) {
    case 'SET_COIN': return Object.assign({}, state, {coin: action.coin} )
    case 'SET_COINS': return Object.assign({}, state, {coins: action.coins} )
    case 'SET_USER': return Object.assign({}, state, {user: action.user} )
    case 'SET_TRANSFER': return { ...state, transfer: action.transfer }
    case 'SET_TRANSFERS': return { ...state, transfers: action.transfers }
    case 'SET_ALL_TRANSFERS': return { ...state, allTransfers: action.transfers }
    case 'SET_ERROR': return { ...state, error: action.error }
    case 'SET_UPLOADED_FILEPATH': return { ...state, uploaded_filepath: action.uploaded_filepath }
    case 'SET_CHILD_ERROR': return { ...state, childErrorMessage: action.childErrorMessage}
    case 'CLEAR_CHILD_ERROR': return { ...state, childErrorMessage: null}
    case 'CLEAR_ERROR': return { ...state, error: null }
    case 'UPDATE_VERIFY_COIN_WIZARD': return { ...state, verifyCoinWizard: action.verifyCoinWizard}
    case 'UPDATE_RECIPIENT_FORM_WIZARD': return { ...state, recipientFormWizard: action.recipientFormWizard}
    default:
      console.warn('UNKNOWN ACTION', action)
      return state
  }
}

const Store = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const debug = true

  const actions = {
    clearEror:  function () {
      dispatch({type: "CLEAR_ERROR"})
    }
  }

  useEffect(() => {
    if (debug) console.log('%c Store %o', 'color: green; font-weight: bold;', state)
  }, [state])

  return (
    <Context.Provider value={{ state, dispatch, actions }}>
      {props.children}
    </Context.Provider>
  )
}

export default Store
